var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.disabled && _vm.imgArr.length < _vm.maxNum
      ? _c("div", { staticClass: "upload-text" }, [
          _vm._v(" 点击上传 "),
          !_vm.multiple
            ? _c("input", {
                ref: "file",
                staticClass: "upload-input",
                attrs: { type: "file" },
                on: {
                  change: function ($event) {
                    return _vm.selectFile($event)
                  },
                },
              })
            : _vm._e(),
          _vm.multiple
            ? _c("input", {
                ref: "file",
                staticClass: "upload-input",
                attrs: { type: "file", multiple: "multiple" },
                on: {
                  change: function ($event) {
                    return _vm.selectFile($event)
                  },
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticStyle: { width: "250px" } }),
    _c(
      "div",
      { staticClass: "avatar-uploader-box" },
      [
        _c(
          "draggable",
          _vm._b(
            {
              staticClass: "drag-box",
              attrs: { element: "div", move: _vm.onMove },
              on: {
                start: function ($event) {
                  _vm.isDragging = true
                },
                end: _vm.onEnd,
              },
              model: {
                value: _vm.imgArr,
                callback: function ($$v) {
                  _vm.imgArr = $$v
                },
                expression: "imgArr",
              },
            },
            "draggable",
            _vm.dragOptions,
            false
          ),
          _vm._l(_vm.imgArr, function (item, i) {
            return _c("div", { key: i, staticClass: "avatar-box" }, [
              _c("img", {
                ref: "avatarBoxImg",
                refInFor: true,
                staticClass: "avatar-box-img",
                attrs: { src: item, alt: "" },
                on: {
                  click: function ($event) {
                    return _vm.showBigImg(i)
                  },
                },
              }),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "delete-icon",
                      on: {
                        click: function ($event) {
                          return _vm.delImageUrl(i)
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-circle-close" })]
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
        _vm._l(_vm.imgArr, function (item, i) {
          return _c(
            "div",
            { key: i },
            [
              _c("el-image", {
                staticStyle: { width: "0", height: "0" },
                attrs: {
                  id: _vm.name + i,
                  src: item,
                  "preview-src-list": _vm.imgArr,
                },
              }),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }