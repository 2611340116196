     <template>
  <div>
    <div
      v-if="!disabled && imgArr.length<maxNum"
      class="upload-text"
    >
      点击上传
      <input
        v-if="!multiple"
        ref="file"
        type="file"
        class="upload-input"
        @change="selectFile($event)"
      >
      <input
        v-if="multiple"
        ref="file"
        type="file"
        class="upload-input"
        multiple="multiple"
        @change="selectFile($event)"
      >
    </div>
    <div style="width: 250px">
      <!-- <el-progress v-if="imgTempArr[0] && imgTempArr[0].progress" :percentage="imgTempArr[0].progress*100"></el-progress> -->
    </div>
    <div class="avatar-uploader-box">
      <draggable
        v-model="imgArr"
        class="drag-box"
        element="div"
        v-bind="dragOptions"
        :move="onMove"
        @start="isDragging=true"
        @end="onEnd"
      >
        <div
          v-for="(item,i) in imgArr"
          :key="i"
          class="avatar-box"
        >
          <img
            ref="avatarBoxImg"
            class="avatar-box-img"
            :src="item"
            alt=""
            @click="showBigImg(i)"
          >
          <div
            v-if="!disabled"
            class="delete-icon"
            @click="delImageUrl(i)"
          >
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
      </draggable>
      <div
        v-for="(item,i) in imgArr"
        :key="i"
      >
        <el-image
          :id="name+i"
          style="width:0;height:0;"
          :src="item"
          :preview-src-list="imgArr"
        >
        </el-image>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { upload } from '@/utils/oss'
/**
 * todo: 图片删除和放大功能,access存储到后端
 */
export default {
  name: 'LoadImgOss',
  components: {
    draggable
  },
  props: {
    // 进度条
    compress: {
      type: Number,
      default: 0.8
    },
    // 是否上传多图
    multiple: {
      type: Boolean,
      default: false
    },
    // 是否预览
    priview: {
      type: Boolean,
      default: true
    },
    defaultUrl: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    url: {
      type: Array,
      default: () => []
    },
    maxSize: {
      type: Number,
      default: 2 * 1024 * 1024
    },
    maxNum: {
      type: Number,
      default: 10
    },
    name: {
      type: String,
      default: 'myElImage'
    }
  },
  data() {
    return {
      imgUrl: '',
      imgArr: [],
      imgTempArr: [],
      dragOptions: {
        group: 'name'
      }
    }
  },
  watch: {
    url: {
      handler(value) {
        this.imgArr = [...value]
        this.imgTempArr = value.map(item => ({
          src: item
        }))
      },
      immediate: true
    }
    // defaultUrl(item){
    //   this.imgTempArr.push({src:item})
    // }
  },
  methods: {
    getImgWidth() {
      return this.$refs.avatarBoxImg
    },
    onEnd() {
      this.$emit('change', this.imgArr)
    },
    showBigImg(i) {
      document.getElementById(this.name + i).click()
    },
    onMove({ relatedContext, draggedContext }) {
      // console.log(relatedContext, draggedContext,888)
      // const relatedElement = relatedContext.element
      // const draggedElement = draggedContext.element
      // return (
      //   (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      // )
      return !this.disabled
    },
    // 删除图片
    delImageUrl(i) {
      this.$confirm('是否确认移除图片?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.imgTempArr.splice(i, 1)
          this.imgArr.splice(i, 1)
          this.$emit('change', this.imgArr)
        })
        .catch(() => {})
    },
    // 断点续传
    selectFile(e) {
      let _this = this
      // 每次重新选择图片时，重置预览图片列表数据
      // this.imgTempArr = []
      // this.imgArr = []
      
      const n = e.target.files.length;
      
      if (n + this.imgArr.length > this.maxNum) {
        this.$message({type: 'error', message: `最多上传${this.maxNum}张图片，请重新选择上传`});
        this.$refs.file.value = '';
        return;
      }

      for (let i = 0; i < e.target.files.length; i++) {
        let fileName = e.target.files[i].name
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[i])// 读取file
        reader.onload = function(data) {
          if (data.loaded > _this.maxSize) {
            _this.$message({type: 'error', message: '图片（' + fileName + '）过大,请重新上传'});
            this.$refs.file.value = '';
            return;
          }
          let image = new Image()// 新建一个img标签（还没有嵌入dom节点）
          let currentFile = null
          image.src = data.target.result // 将图片的路径设置成file路径
          _this.imgTempArr.push({
            title: fileName,
            src: data.target.result,
            // progress: 0,
            index: i,
            errorText: '' // 上传失败文案
          })
          image.onload = function() {
            // 压缩的思路是创建一个图片，将file等于这个图片，然后创建一个canvas图层，将canvas等比例缩放，然后用canvas的drawImage将图片与canvas结合起来
            // 然后将canvas的base64转成file即可
            // let _data = _this.onImgCompression(image)
            currentFile = _this.dataURLtoFile(image.src, fileName)
            _this.multipartUpload(currentFile, i, fileName)
          }
        }
      }
      this.$refs.file.value = '';
    },
    // 压缩图片
    onImgCompression(img) {
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')
      let initSize = img.src.length
      let width = img.width
      let height = img.height
      canvas.width = width
      canvas.height = height
      // 铺底色
      ctx.fillStyle = '#fff'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0, width, height)
      // 进行压缩
      let compress = this.compress || 0.8 // 压缩率
      return canvas.toDataURL('image/jpeg', compress)
    },
    // 将裁剪base64的图片转换为file文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 定义上传方法
    async multipartUpload(file) {
      this.loading = true;
      upload(file).then((res)=>{
        const url = res.url.split('?')[0];
        this.imgArr.push(url);
        this.$emit('change', this.imgArr);
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>
<style lang="scss">
.drag-box{
    display: flex;
    flex-wrap: wrap;
}
.upload-text{
  width: 80px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  position: relative;
}
.upload-input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: Alpha(opacity=0);
  -moz-opacity:0;
  opacity: 0;
  cursor: pointer;
}
.avatar-uploader-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.avatar-box {
  position: relative;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;
  /* border: 1px solid #dcdfe6; */
  min-width: 100px;
  min-height: 100px;
  flex-wrap: wrap;
  max-height: 200px;
  .delete-icon {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 0 0 0 10px;
    background: rgba(0, 0, 0, 0.3);
    line-height: 20px;
    text-align: center;
  }
  .avatar-box-img{
    width: 100px;
    cursor: pointer;
    border: 1px solid #dcdfe6;
  }
}
.avatar-box img {
  // width: 100%;
  // border: 1px solid #dcdfe6;
}
.upload-list__item{
  display: flex;
  margin-top: 10px;
}

.upload-list__item-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
}
.error-text{
  color: red;
}
</style>
